:root {
  --primary-blue: #364059;
  --secondary-orange: #FF8A23;
  --light-white: #f0f0f0;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--primary-blue);
}

.signup_container {
  background-color: var(--primary-blue);
  width: 100%;
  
  /* min-height: 100vh; */
  position: relative;
}

.signup_container h3, .signup_container h6 {
  color: #fff;
}

.logo_container {
  /* position: absolute; */
  left:0;
  top:0;
  margin: 10px;
  text-align: left;
  margin-bottom: 10px;
}
.logo_container a img{
  width: 200px;
}

.signup_container h3 {
  font: normal normal 500 34px/42px 'Montserrat', sans-serif;
}

.signup_container h6 {
  font: normal normal medium 18px/22px 'Montserrat', sans-serif;
}

.signup_container h5 {
  color: var(--secondary-orange);
  margin-top: 40px;
  font: normal normal 600 34px/42px 'Montserrat', sans-serif;
}

.input_container {
  margin-bottom: 20px;
}

.row-container{
  display: flex;
  flex-direction: row-reverse;
}


.form-title {
  margin-top: -20%;
 text-align: center;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.form-title h5{
  font-size: 25px;
  color: orange;
  text-align: center;
}


.right-container{
  display: grid;
  place-items: center;
  width: 100%;
  padding: 1%;
}
.form-container{
  position: relative;
  background: white;
  padding: 5% 10% 5% 10%;
  border-radius: 5%;
  width: 80%;
 
}

.magnifying-glass{
  width: 155px;
  height: 250px;
  position: absolute;
  left: -25%;
  top: -10%;
}

.form-details{
  padding-top: 10px;
  width: 80%;
  padding-bottom: 10%;
}

.ellipse-feature{
  min-height: 200px;
  max-height: 220px;
  margin-bottom: 40px;
  position: absolute;
  left:0;
  bottom: 0;
  /* height: 100%; */
  background:
    transparent url(./images/white_ecllipse.svg) 0% 0% no-repeat padding-box;
}
.ellipse-feature1{
  display: none;
}


.feature-companylist {
  width: 60%;
  font-size: 18px;
  margin: 50px 30px 0 40px;
  display: inline-block;
}
.footer-main{
 
  width:100%;
}
.footer-container{
  min-height: 40px;
  background: orange;
  color: white;
  width:100%;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  position: fixed;
  bottom:0;
}
.small-text-mobileview{
  display: none;
}

.consultation_content{
  position: absolute;
  left: 10%;
}

.feature-companylist h6{
  font-size: 15px;
}

.consultation_content h6{
  font-size: 25px;
  font-weight: bold;
}
.consultation_content h3{
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: orange;
}
.consultation_content p{
  text-align: center;
  color: white;
  font-size: 14px;
}
.consultation_container {
  text-align: center;
  color: var(--primary-blue);
}

.consultation_container h4 {
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 10px;
}

.consultation_container small {
  font-size: 12px;
}

.form_container {
  padding: 30px;
  background-color: #fff;
  border-radius: 30px;
  width: 85%;
  margin: 0px auto;
  position: relative;
}

.bg_white {
  background-color: #fff;
  margin: 5px;
}

.bg_white_rounded {
  background-color: #fff;
  padding: 30px;
  border-radius: 20px;
}

.magnifying_glass {
  position: absolute;
  left: -47%;
  top: -10%;
  width: 255px;
  height: 360px;
}

.form_container input, .form_container select {
  border: 1px solid #D8D8D8;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  min-height: 30px;
  /* margin-bottom: 10px; */
  font: normal normal 500 18px/22px 'Montserrat', sans-serif;
  color: #BFBFD4;
}

.form_container input[type=number]::-webkit-outer-spin-button,
.form_container input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: #BFBFD4 !important;
  font-size: 14px;
}

input:focus{
  border-color: #1890ff; /* Change the border color as needed */
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); */
}
.form-control:focus {
  border-color: #40a9ff; /* Ant Design default focus border color */
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1); /* Change the box shadow during focus */
}
.form-control{
  height: 40px; 
}

.form-label{
  font-size: 12px;
}

.invalid-feedback {
  color: red;
  font-size: 12px; /* Adjust the font size as needed */
  margin-top: 4px; /* Add some space at the top */
}

.form-control:hover {
  border-color: #40a9ff; /* Ant Design default focus border color */
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);  */
}

.ant-select-selection-placeholder {
  font-family: 'Montserrat', sans-serif;
}

.ant-picker-input > input::placeholder {
  font-family: 'Montserrat', sans-serif;
}
.ant-picker {
  height: 40px; 
}
.ant-select {
  height: 40px; 
  width: 80%;
}


.form_container input[type=number] {
  -moz-appearance: textfield;
}

.form_container select {
  padding: 5px 10px 5px 5px;
}



.form_container .form-select:focus {
  outline: 2px solid black;
  box-shadow: none;
  border-color: unset;
}

.form_container label {
  display: block;
  margin: 5px;
  color: #000000;
  font: normal normal 500 18px/22px Montserrat;

}

.form_container .btn-warning {
  background-color: var(--secondary-orange);
  color: #fff
}

.form_container .btn {
  margin-top: 15px;
  font: normal normal medium 18px/22px 'Montserrat', sans-serif;
}

.rating_star {
  /* min-height: 130px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  /* margin-left: 5%; */
  color: rgb(255, 255, 255);
  margin-bottom: 30px;
}
.rating_star ul li{
  font-size: 15px;
  text-align: left;
}
.small-text{
  color: white;
  font-size: 10px;
  text-align: left;
  margin-left: -5%;
}
.rating_star svg {
  color: var(--secondary-orange);
}

.feautre_heading h6 {
  color: var(--secondary-orange);
  font: normal normal 600 20px/20px 'Montserrat', sans-serif;
  font-size: 18px;
}

.feautre_heading {
  margin: 0;
}


.feautre_heading h6 {
  padding: 10% 0 0 5%;
  text-align: left;
}

.feautre_heading img {
  margin: 10px;
}

.consultation_content h5 svg {
  font-size: 2rem;
}

.consultation_content h5 {
  font-size: 25px;
  margin-top: 15%;
}

.rating_star h6 {
  color: var(--secondary-orange);
  /* font-size: 18px;
  font-weight: 800; */
  font: normal normal 600 28px/34px Montserrat;
  margin: 10px 0;
}

.rating_star div {
  color: var(--light-white);
  font: normal normal medium 18px/22px Montserrat;
}

.dollar_icon {
  position: absolute;
  bottom: 5px;
}

.dollar_input {
  position: relative;
}

.dollar_input input {
  padding: 5px 5px 5px 30px;
}

.ellipse_feature {
  height: 200px;
  background:
    transparent url(./images/white_ecllipse.svg) 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: 0;
  width: 50%;
}

.feature_companylist {
  width: 70%;
  margin: 50px 0 0 40px;
  display: inline-block;
}
.form-description{
  margin: 10px;
}

@media only screen and (max-width: 600px) {
  .magnifying_glass {
    position: absolute;
    left: -11%;
    top: -14%;
    width: 85px;
    height: 150px;
  }

  .feautre_heading {
    /* margin-top: 5%; */
    text-align: center;
  }

  .signup_container {
    height: unset;
    /* overflow: auto; */
  }

  .logo_container {
    margin-bottom: 60px;
  }


  .rating_star {
    margin-bottom: 60px;
  }

  .feautre_heading img {
    width: 105px;
    height: 33px;
  }

  .consultation_content {
    margin-top: 10px;
  }
  .consultation_content h5 {
    font-size: 25px;
    /* margin-top: 2%; */
  }

  .ellipse-feature {
    background-image: none;
    position: relative;
    /* position: absolute;
    bottom: 0; */
    width: unset;
    height: auto;
  }

  .feature-companylist {
    width: 80%;
    margin: 15px;
  }
  
  .magnifying-glass{
    position: absolute;
    left: -13%;
    top: -5%;
    width: 55px;
    height: 100px;
  }
  
  .form-details{
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .magnifying_glass {
    position: absolute;
    left: -13%;
    top: -10%;
    width: 90px;
    height: 120px;
  }


  .feautre_heading {
    text-align: center;
  }
}

@media only screen and (max-width: 375px) {}

@media only screen and (max-width: 320px) {
  .magnifying_glass {
    position: absolute;
    left: -14%;
    top: -12%;
    width: 90px;
    height: 120px;
  }

  /* .feautre_heading img {
    width: 205px;
    height: 55px;
  } */
}

@media only screen and (min-width: 600px) {
  .magnifying_glass {
    position: absolute;
    left: -18%;
    top: -10%;
    width: 100px;
    height: 200px;
  }

  .signup_container {
    /* height: unset; */
    /* overflow: auto; */
    min-height: 100vh;
  }

  .feautre_heading img {
    width: 105px;
    height: 33px;
  }

  .logo_container {
    margin-bottom: 20px;
  }

  .feautre_heading {
    text-align: center;
  }

  .ellipse_feature {
    background-size: 96% 296px;
    position: absolute;
    bottom: 0;
    width: unset;
  }

  .feature_companylist {
    width: 45%;
    /* margin: 50px 0 0 40px; */
  }

  /* .consultation_content {
    margin-bottom: 150px;
    margin-top: 40px;
  } */

  .form_container {
    margin-bottom: 0;
  }

  .rating_star {
    margin-bottom: 90px;
  }
}

@media (max-width: 992px) {
  .logo_container {
    margin-bottom: 20px;
    text-align: center;
  }
  .logo_container img {
    width: 200px;
    /* margin-bottom: 30px; */
  }
  .row-container{
    display: flex;
    flex-direction: column-reverse;
  }

  .small-text{
    display: none;
  }
  .ellipse-feature {
   display: none;
  }
  .magnifying-glass{
    display: none;
  }
  .form-container{
    width: 100%;

  }
  .form-title{
    padding-top: 2%;
  }
  .form-details{
    width: 100%;
    padding-bottom: 5%;
  }
  .consultation_content{
    position: relative;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  .consultation_content title{
    width: 70%;
  }
  .consultation_content h6{
    text-align: center;
  }
  .company-item{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ellipse-feature1{
    display: block;
    background-image: none;
    position: relative;
    /* position: absolute;
    bottom: 0; */
    width: unset;
    height: 100%;
    margin-bottom:20% ;
  }
  .small-text-mobileview{
    display: block;
    color: white;
    font-size: 10px;
    text-align: left;
    margin-top: 5%;
    margin-left: -5%;
    padding-bottom: 20%;
  }
  .footer-container{
    font-size: 12px;
  }
}


